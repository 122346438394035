import React from "react";
import {Form, Tab} from "semantic-ui-react";
import DocumentListEdit from "../../../shared/documents/DocumentListEdit";
import History from "../../../shared/History";
import ControlledModal from "../../../shared/modals/ControlledModal";
import {UserPermissionsContext} from "../../../Contexts";

const  initState = {
        modal: false,
        formData: {
            id: "",
            model: "",
            number: "",
            trailerNumber: "",
            description: "",
            documents: [],
            histories: [],
            types: [],
            companies: []
        },
        errorText: "",
        typeList: [],
        companiesList:[]
}


export default class TransportEditModal extends React.Component {
    static contextType = UserPermissionsContext;
    state = {
        ...initState
    };

    getId = () => {

        let id = this.props.id == null || this.props.id === undefined ? this.state.formData.id : this.props.id;
        return id === "" ? null : id;
    }

    load = () => {
        const {parameter} = this.props;
        this.setState({
            formData: {...this.state.formData, documents: []}
        });
        let companiesName = []
        callApi('companies/getAll')
            .then((data) => {
                companiesName = data
                this.setState({
                    companiesList: companiesName ? companiesName.map(item => ({
                        key: item.id,
                        value: item.id,
                        text: item.name
                    })) : []
                })
            })
        callApi("TransportTypes/GetList", this.props.id).then(typeList => {
            this.setState({
                typeList: typeList.map((x) => ({ key: x.id, value: x.id, text: x.name }))
            })
        })
        if (this.props.id) {
            callApi(`${parameter}/GetById`, this.props.id).then(data => {
                this.setState({
                    formData: {...data, trailerNumber: data.trailerNumber ? data.trailerNumber : '', companies: data.companies.map(item => item.id),  types: data.types ? data.types.map(item => item.id) : null}
                });
            });
        }
    }

    save = (e, isUniteCampaigns = false) => {
        const {parameter} = this.props;
        return callApi(`${parameter}/SaveOrCreate`, {...this.state.formData, isUniteCampaigns})
            .then(data => {
                if (data.error !== "") {
                    this.setState({
                        errorText: data.error
                    });
                }
                if (this.props.onSave !== undefined)
                    this.props.onSave(e, { value: data.id, parameter });
                return data;
            });
    }

    onClose = (e) => {
        this.setState({
            ...initState
        });
        this.props.onClose && this.props.onClose(e)
    }

    onDocumentsChange = (documents) => {
        this.setState(prevState => {
            return { formData: { ...prevState.formData, documents } };
        });
    }

    handleChange = (e, { name, value }) => {

        this.setState(prevState => {
            return { formData: { ...prevState.formData, [name]: value } };
        });
    }

    getPanes = () => {
        const { isNotHistory, parameter } = this.props;
        const extendedEditTransports = this.context.permissionsData.permissions.includes("ExtendedEditTransports");
        const isCreate = this.getId() === null;

        let pannes = [
            {
                menuItem: t('Information'), render: () => <Tab.Pane>
                    <Form error className='create-edit-form'>
                        <Form.Field>
                            <Form.Input label={t('Model')} name="model" value={this.state.formData.model}
                                        onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label={t('Number')} name="number" value={this.state.formData.number}
                                        onChange={this.handleChange} disabled={!extendedEditTransports && !isCreate}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label={t('Description')} name="description" value={this.state.formData.description}
                                        onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label={t('SB comment')} disabled={!extendedEditTransports} name="securityComment" value={this.state.formData.securityComment}
                                        onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Dropdown
                                label={t('companies')}
                                fluid
                                multiple
                                selection
                                search
                                name="companies"
                                placeholder={t('companies')}
                                disabled={!extendedEditTransports}
                                value={this.state.formData.companies}
                                options={this.state.companiesList}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        {parameter === 'trailer' &&
                        <Form.Field>
                            <label>{t('Type')}</label>
                            <Form.Dropdown fluid multiple search selection name="types"
                                           value={this.state.formData.types} options={this.state.typeList}
                                           onChange={this.handleChange}/>
                        </Form.Field>
                        }
                        <DocumentListEdit
                            notDelete={!this.context.permissionsData.permissions.includes("ExtendedEditTransports")}
                            documents={this.state.formData.documents}
                            onDocumentsChange={this.onDocumentsChange}
                            typesApiUrl="DocumentTypes/GetDocumentTypeList"
                            type="Transport"
                            onSaveForm={this.save}
                        />

                        {/*{this.state.errorText != "" ? <Message error content={this.state.errorText} /> : ""}*/}
                    </Form>
                </Tab.Pane>
            }
        ];

        if (!isNotHistory) {
            pannes.push({ menuItem: t('History'), render: () => <Tab.Pane><History items={this.state.formData.histories} /></Tab.Pane> });
        }

        return pannes;
    }

    render() {
        return (
            <ControlledModal
                buttonName={this.props.children}
                buttonSize={this.props.size}
                title={this.props.title}
                onOpen={this.load}
                buttonClassName={this.props.buttonClassName}
                onClose={this.onClose}
                error={this.state.errorText}
                isDisabled={this.props.isDisabled}
                saveButtonName={this.props.actionButton}
                onSave={this.save}
                textForIsDoubleModal={this.props.parameter !== 'trailer' ? 'add_vehicle_to_your_TC' : 'add_trailer_to_your_TC'}
            >
                <Tab panes={this.getPanes()} />
            </ControlledModal>
        );
    }
}
