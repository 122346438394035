import React from "react";
import {
    Grid,
    Tab,
    Icon,
} from "semantic-ui-react";
import CustomFieldsTable from "../../../shared/customFieldsEditor/CustomFieldsTable";
import PlatformEditForm from "./PlatformEditForm";
import GateEditor from "./GateEditor";
import ControlledModal from "../../../shared/modals/ControlledModal";
import ScheduleModal from "./ScheduleModal";
import {UserPermissionsContext} from "../../../Contexts";

export default class PlatformEditModal extends React.Component {
    static contextType = UserPermissionsContext;

    state = {
        modal: false,
        formData: {
            name: "",
            address: "",
            gates: [],
            customFieldsForVisit: [],
            customFieldsForTask: [],
            customFieldsForOrder: [],
            timeZoneId: null
        },
        formDataOld: {},
        errorText: "",
        editGateNumber: null,
        editGateActivitiesValues: [],
        timeZoneList: [],
        allActivities:[],
        oldFormData: null,
        confirmation: {
            open: false,
            content: '',
            onConfirm: () => {
            }
        },
        specificationsList:[],
        onlyCurrentUserActivities: []
    };

    getDataForPlatform = () => {
        callApi("Activities/GetListForPlatform", {
            id: this.props.id
        }).then(activities => {
            this.setState({
                allActivities: activities,
            });
        });
        callApi("Activities/GetListForPlatform", {
            id: this.props.id,
            onlyCurrentUser: true
        }).then(activities => {
            this.setState({
                onlyCurrentUserActivities: activities,
            });
        });
        callApi("GateCustomFields/GetFieldsForCreate").then(specificationsList => {
            this.setState({
                specificationsList: specificationsList,
            });
        });
    }

    load = () => {
        this.getDataForPlatform();
        return callApi("Definitions/GetAllTimeZones").then(timeZones => {
            callApi("Platforms/GetFor", this.props.id).then(data => {
                this.setState({
                    timeZoneList: timeZones.map(x => { return { key: x.id, text: x.name, value: x.id }; }),
                    formData: data,
                    formDataOld: data
                });
            });
        });
    };

    changeFormData = (changer) => {
        let formData = this.state.formData;
        changer(formData);
        this.setState({ formData: formData });
    };

    handleChange = (e, { name, value, checked }) => {
        this.setState((prevState) => {
            return { formData: { ...prevState.formData, [name]: checked !== undefined ? checked : value } };
        });
    };

    mapSaveFormCustom = (custom = []) => {
        return custom.map(i => ({
            fieldMatrixAccessTypes: i.fieldMatrixAccessTypes,
            id: i.id,
            isActive: i.isActive,
            name: i.name,
            type: i.type,
            valuesForSelectTypeAsArray: i.valuesForSelectTypeAsArray
        }))
    }

    save = () => {
        const {formData} = this.state;
        let formDataCustomFieldsNew = {
            customFieldsForVisit: this.mapSaveFormCustom(formData.customFieldsForVisit),
            customFieldsForTask: this.mapSaveFormCustom(formData.customFieldsForTask),
            customFieldsForOrder: this.mapSaveFormCustom(formData.customFieldsForOrder)
        };
        const formDataNew = {...this.state.formData, ...formDataCustomFieldsNew};
        return callApi("Platforms/SaveOrCreate", formDataNew)
            .then(data => {
                if (data.error !== "") {
                    this.setState({
                        errorText: data.error
                    });
                }
                this.setState({
                    formDataOld: {}
                });
                return data;
            });
    };

    onChangeCustomFields = (customFields) => {
        this.setState(prevState => {
            return { formData: { ...prevState.formData, ...customFields } };
        });
    };

    changeFormDataGates = (gateEdit) => {

       let valid = true;
       this.setState(prevState => {
            const {formData} = prevState;
            const {gates: gatesState = []} = formData;
            const {index, gate} = gateEdit;
            const gatesNew = [...gatesState];

            if (index !== undefined) {
                gatesNew.splice(index, 1, gate);
            } else {
                gatesNew.push(gate);
            }
            return {formData: {...prevState.formData, gates: gatesNew}};
        });

        return Promise.resolve();
    };

    hasPlatformChanges = () => {
        const {formDataOld, formData } = this.state;

        return JSON.stringify(formDataOld) !== JSON.stringify(formData);
    };

    onCloseScheduleGate = () => {
        this.setState({
            errorText: ""
        });
    };

    clearFormData = () => {
        this.setState({ oldFormData: null, newFormData: null });
    };

    onClose = () => {
        const {onClose} = this.props;
        onClose && onClose();
        this.clearFormData();
    };

    render() {
        const {confirmation} = this.state;

        const { permissionsData } = this.context;
        const { permissions } = permissionsData;

        let panes = [
            {
                menuItem: t('Information'), render: () => <Tab.Pane>
                    <PlatformEditForm
                        editPermission={permissions.includes("EditPlatforms")}
                        formData={this.state.formData}
                        handleChange={this.handleChange}
                        timeZoneList={this.state.timeZoneList}
                    />
                </Tab.Pane>
            },
            {
                menuItem: t('Gate'), render: () => <Tab.Pane>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column textAlign="right">
                                <ScheduleModal
                                    size="small"
                                    title={t("Adding a gate")}
                                    actionButton={t("SaveButton")}
                                    error={this.state.errorText}
                                    id={null}
                                    isActive={true}
                                    formData={[]}
                                    specificationsList={this.state.specificationsList}
                                    allNameGates={this.state.formData.gates && this.state.formData.gates.map(item => item.number)}
                                    allActivities={this.state.allActivities}
                                    onlyCurrentUserActivities={this.state.onlyCurrentUserActivities}
                                    onSave={this.changeFormDataGates}
                                    onClose={this.onCloseScheduleGate}
                                    platformId={this.props.id}
                                    availableForEdit
                                    editPlatformPermission={permissions.includes("EditPlatforms")}
                                    /*onClose={load}*/>
                                    <Icon name="plus"/>{` ${t("Add gate")}`}
                                </ScheduleModal>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div className={"init-scroll"}>
                        <GateEditor
                            gates={this.state.formData.gates}
                            error={this.state.errorText}
                            changeFormData={this.changeFormDataGates}
                            onCloseScheduleEdit={this.onCloseScheduleGate}
                            platformId={this.props.id}
                            allActivities={this.state.allActivities}
                            onlyCurrentUserActivities={this.state.onlyCurrentUserActivities}
                        />
                    </div>
                </Tab.Pane>
            }];

        permissions.includes('EditPlatforms') && panes.splice(1, 0, {
            menuItem: t('Custom fields'), render: () => <Tab.Pane>{
                this.props.id == null
                    ? ""
                    : <div style={{ marginTop: "15px" }}>
                        <CustomFieldsTable
                            isPlatform
                            customFields={this.state.formData}
                            onChangeCustomFields={this.onChangeCustomFields}
                        />
                    </div>
            }</Tab.Pane>
        });

        return (
            <ControlledModal
                buttonName={this.props.children}
                buttonSize={this.props.size}
                title={this.props.title}
                onOpen={this.load}
                closeOnEscape={true}
                closeOnDimmerClick={false}
                onClose={this.onClose}
                error={this.state.errorText}
                saveButtonName={this.props.actionButton}
                onSave={this.save}
                isConfirmForCloseModal={this.hasPlatformChanges()}
            >
                <Tab panes={panes} />
            </ControlledModal>);
    }
};
