import React from "react";
import { Modal, Button, Grid, Label, Select, Tab, Input, Icon, Table, Dropdown, Form, Message, Popup, TextArea } from "semantic-ui-react";
import CustomFieldsTable from "../../../shared/customFieldsEditor/CustomFieldsTable";
import ControlledModal from "../../../shared/modals/ControlledModal";

export default class GlobalSettingsEditModal extends React.Component {

    state = {
        modal: false,
        formData: {
            customFieldsForVisit: [],
            customFieldsForTask: [],
            customFieldsForOrder: [],
            customFieldsForOrdersModule: [],
        },
        errorText: ""
    };

    load = () => {
        return callApi("GlobalSettings/Get")
            .then(data => {
                this.setState({ formData: {...this.state.formData, ...data}, errorText: "" });
            });
    }

    save = () => {
        let formDataNew = {};
        const {formData} = this.state;
        for (let key in formData) {
            if (Array.isArray(formData[key])) {
                formDataNew[key] = formData[key].map(i => ({
                    fieldMatrixAccessTypes: i.fieldMatrixAccessTypes,
                    id: i.id,
                    isActive: i.isActive,
                    name: i.name,
                    type: i.type,
                    valuesForSelectTypeAsArray: i.valuesForSelectTypeAsArray
                }))
            }
        }
        return callApi("GlobalSettings/Save", formDataNew)
            .then(data => {
                if (data.error !== "") {
                    this.setState({
                        errorText: data.error
                    });
                }
                return data;
            });
    }

    onChangeCustomFields = (customFields) => {
        this.setState(prevState => {
            return { formData: { ...prevState.formData, ...customFields } };
        });
    }

    render() {
        return (<ControlledModal
            buttonName={this.props.children}
            buttonSize={this.props.size}
            title={this.props.title}
            onOpen={this.load}
            onClose={this.props.onClose}
            error={this.state.errorText}
            saveButtonName={this.props.actionButton}
            onSave={this.save}>
            <CustomFieldsTable
                customFields={this.state.formData}
                onChangeCustomFields={this.onChangeCustomFields}
            />
        </ControlledModal>);
    }
};
