import React from "react";
import {Modal, Form, Button, Grid, Icon, Message, TextArea, Segment, Tab, Dropdown} from "semantic-ui-react";
import DocumentListEdit from "../../../shared/documents/DocumentListEdit";
import DatePicker from "react-datepicker";
import History from "../../../shared/History";
import DataProcessingAgreementForDriver from "./DataProcessingAgreementForDriver";

import ControlledModal from "../../../shared/modals/ControlledModal";
import {UserPermissionsContext} from "../../../Contexts";
import { toast } from "react-toastify";

export default class DriverEditModal extends React.Component {
    static contextType = UserPermissionsContext;
    state = this.initialState;

    get initialState() {
        return {
            modal: false,
            formData: {
                id: "",
                firstName: "",
                lastName: "",
                middleName: "",
                passportSeriesAndNumber: "",
                passportDate: null,
                passportDepartment: "",
                passportDepartmentCode: "",
                driverLicenseSeries: "",
                driverLicenseValidDate: null,
                phone: "",
                securityCheckState: null,
                documents: [],
                histories: [],
                blockedActivities: [],
                companies: [],
                acceptPersonalDataProcessingDate: null
            },
            allpermissions: [],
            errorText: "",
            activityList: [],
        };
    }
    getId = () => {
        
        let id = this.props.id == null || this.props.id === undefined ? this.state.formData.id : this.props.id;
        return id === "" ? null : id;
    }

    load = () => {
        callApi('companies/getAll')
            .then((data) => {
                this.setState({
                    companiesList: data ? data.map(item => ({
                        key: item.id,
                        value: item.id,
                        text: item.name
                    })) : []
                })
            })
            .then(() => {
                return callApi('Activities/GetList').then(data => {
                    this.setState({
                        activityList: data ? data.map(item => ({
                            key: item.id,
                            value: item.id,
                            text: item.name
                        })) : []
                    })
                });
            })            
            .then(() => {
                let id = this.getId();
                if (id !== null)
                    callApi("Drivers/GetById", id).then(data => {
                        this.setState({
                            formData: {
                                ...data,
                                companies: data.companies.map(item => item.id),
                                blockedActivities: data.blockedActivities.map(item => item.id)
                            }
                        });
                    });
            });
    }

    save = (e, isUniteCampaigns = false) => {
        return callApi("Drivers/SaveOrCreate", {...this.state.formData, isUniteCampaigns})
            .then(data => {
                if (data.error !== "") {
                    toast.error(t(data.error));
                    return data;
                }

                var formDataTemp = this.state.formData;
                if(formDataTemp['id'] === undefined || formDataTemp['id'] === ''){
                    formDataTemp['id'] = data.id;
                    this.setState({
                        formData: formDataTemp
                    });
                    toast.info(t('driver created'));
                }else{
                    toast.info(t('driver updated'));
                }

                if (this.props.onSave !== undefined)
                    this.props.onSave(e, {value: data.id});
            });
    }

    onDocumentsChange = (documents) => {
        this.setState((prevState) => {
            return {formData: {...prevState.formData, documents}};
        });
    }

    handleChange = (e, {name, value, checked}) => {
        this.setState((prevState) => {
            return {formData: {...prevState.formData, [name]: checked !== undefined ? checked : value}};
        });
    }

    getPanes = () => {
        const {isNotHistory} = this.props;
        const extendedEditDrivers = this.context.permissionsData.permissions.includes("ExtendEditDrivers");
        const isCreate = this.getId() === null;

        let pannes = [
            {
                menuItem: t('Information'), render: () => <Tab.Pane>
                    <Form error className='create-edit-form'>
                        <Grid columns='equal' className="margin-bottom-10">
                            <Grid.Row columns='equal'>
                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t('Last name')} name='lastName' disabled={!extendedEditDrivers && !isCreate}
                                                    value={this.state.formData.lastName} onChange={this.handleChange}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input label={t('First name')} name='firstName' disabled={!extendedEditDrivers && !isCreate}
                                                    value={this.state.formData.firstName} onChange={this.handleChange}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input label={t('Patronymic')} name='middleName' disabled={!extendedEditDrivers && !isCreate}
                                                    value={this.state.formData.middleName}
                                                    onChange={this.handleChange}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input label={t('Phone')} name='phone'
                                                    value={this.state.formData.phone || ''}
                                                    onChange={this.handleChange}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t('Driver license')}</label>
                                        <Segment color='yellow'>
                                            <Form.Group>
                                                <Form.Input label={t('WU series and number')} name='driverLicenseSeries'
                                                            value={this.state.formData.driverLicenseSeries || ''}
                                                            width={8} onChange={this.handleChange}/>
                                                <Form.Field width={8}>
                                                    <label>{t('Expiration Date WU')}</label>
                                                    <DatePicker
                                                        locale={localStorage.getItem("currentCulture")}
                                                        selected={parseDate(this.state.formData.driverLicenseValidDate)}
                                                        dateFormat="dd.MM.yyyy"
                                                        className={(this.state.formData.driverLicenseValidDate != null && parseDate(this.state.formData.driverLicenseValidDate) < Date.now() )? 'time-error' : ''}
                                                        onChange={(ev) => {
                                                            this.setState({formData: Object.assign(this.state.formData, {driverLicenseValidDate: dateToString(ev)})});
                                                        }}
                                                    />
                                                </Form.Field>
                                            </Form.Group>
                                        </Segment>
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <label>{t('Passport')}</label>
                                        <Segment color='yellow'>
                                            <Form.Group>
                                                <Form.Input label={t('SeriesAndNumber') + '*'} name='passportSeriesAndNumber'
                                                            value={this.state.formData.passportSeriesAndNumber || ''} width={16}
                                                            onChange={(e, {name, value, checked})=>{
                                                                value = value.split(" ").join("");
                                                                this.handleChange(e, {name, value, checked});
                                                            }}
                                                            disabled={!extendedEditDrivers && !isCreate}
                                                />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Field width={8} disabled={!extendedEditDrivers && !isCreate}>
                                                    <label>{t('Date of issue')}</label>
                                                    <DatePicker
                                                        locale={localStorage.getItem("currentCulture")}
                                                        selected={parseDate(this.state.formData.passportDate)}
                                                        dateFormat="dd.MM.yyyy"
                                                        onChange={(ev) => {
                                                            this.setState({formData: Object.assign(this.state.formData, {passportDate: dateToString(ev)})});
                                                        }}
                                                    />
                                                </Form.Field>
                                                <Form.Input label={t('Unit Code')} name='passportDepartmentCode'
                                                            value={this.state.formData.passportDepartmentCode} width={8}
                                                            onChange={this.handleChange} disabled={!extendedEditDrivers && !isCreate}/>
                                            </Form.Group>
                                            <Form.Field
                                                value={this.state.formData.passportDepartment || ''}
                                                name='passportDepartment'
                                                control={TextArea}
                                                label={t('Issuer')}
                                                onChange={this.handleChange}
                                                disabled={!extendedEditDrivers && !isCreate}
                                            />
                                        </Segment>
                                    </Form.Field>
                                    <Form.Field>
                                        <DocumentListEdit
                                            notDelete={!extendedEditDrivers}
                                            documents={this.state.formData.documents}
                                            onDocumentsChange={this.onDocumentsChange}
                                            typesApiUrl="DocumentTypes/GetDocumentTypeList"
                                            type="Driver"
                                            onSaveForm={this.save}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className={'padding-top-0'} columns="equal">
                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t('SB comment')} disabled={!extendedEditDrivers} name="securityComment" value={this.state.formData.securityComment || ''}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                    <Form.Dropdown
                                        selection
                                        label={t('companies')}
                                        fluid
                                        multiple
                                        search
                                        name="companies"
                                        placeholder={t('companies')}
                                        disabled={!extendedEditDrivers}
                                        value={this.state.formData.companies}
                                        options={this.state.companiesList}
                                        onChange={(e, {value}) => this.setState({formData: Object.assign(this.state.formData, {companies: value.length ? value : []})})}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className={'padding-top-0'} columns="equal">
                                <Grid.Column>
                                    <Form.Dropdown
                                        selection
                                        label={t('Activities blocked')}
                                        fluid
                                        multiple
                                        search
                                        name="blockedActivities"
                                        placeholder={t('activities')}
                                        disabled={!extendedEditDrivers}
                                        value={this.state.formData.blockedActivities}
                                        options={this.state.activityList}
                                        onChange={(e, {value}) => this.setState({formData: Object.assign(this.state.formData, {blockedActivities: value.length ? value : []})})}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                </Grid.Column>
                            </Grid.Row>                          
                            {this.context.permissionsData.permissions.includes("AvailableOpenDataProcessingAgreementForDriver") &&
                            (this.state.formData.id !== '' && this.state.formData.id !== null) && 
                            <Grid.Row className={'padding-top-0'} columns="equal">
                                <Grid.Column>
                                    <Form.Field>
                                        <label>{t('InformationAboutOpenDataProcessingAgreementForDriver')}</label>
                                        <Segment color='yellow'>
                                            <Form.Group>
                                                <Form.Field width={5}>
                                                    <label>{t('acceptPersonalDataProcessingDate')}</label>
                                                    <input
                                                        value={this.state.formData.acceptPersonalDataProcessingDate || ''}
                                                        disabled={true}
                                                        className={this.isValidAcceptPersonalDataProcessingDate() ? 'time-error' : ''}
                                                    />
                                                </Form.Field>
                                                <Form.Field width={11}>
                                                    <DataProcessingAgreementForDriver 
                                                        driverId={this.state.formData.id}
                                                        onAccept={this.load}
                                                    />
                                                </Form.Field>
                                            </Form.Group>
                                            
                                        </Segment>
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                            }
                        </Grid>
                    </Form>
                </Tab.Pane>
            }
        ];

        if (!isNotHistory && this.context.permissionsData.permissions.includes("ExtendedEditDrivers")) {
            pannes.push({
                menuItem: t('History'),
                render: () => <Tab.Pane><History items={this.state.formData.histories}/></Tab.Pane>
            });
        }

        return pannes;
    }

    onClose = (e) => {
        this.setState(this.initialState);
        this.props.onClose && this.props.onClose(e)
    }

    render() {
        return (
            <ControlledModal
                buttonName={this.props.children}
                buttonSize={this.props.size}
                title={this.getId() === null ? t('Adding a driver') : t('Driver editing')}
                onOpen={this.load}
                onClose={this.onClose}
                isDisabled={this.props.isDisabled}
                error={this.state.errorText}
                saveButtonName={this.getId() === null ? t("Add driver") : t("Driver editing")}
                onSave={this.save}
                textForIsDoubleModal={'add_driver_to_your_TC'}
                dontCloseAfterSave
            >
                <Tab panes={this.getPanes()}/>
            </ControlledModal>
        );
    }

    isValidAcceptPersonalDataProcessingDate = () => {
        var acceptPersonalDataProcessingDate = this.state.formData.acceptPersonalDataProcessingDate;
        //var acceptPersonalDataProcessingDate = '18.06.2019'
        if(acceptPersonalDataProcessingDate !== null && acceptPersonalDataProcessingDate !== '') {
            var d = parseDate(acceptPersonalDataProcessingDate);
            var year = d.getFullYear();
            var month = d.getMonth();
            var day = d.getDate();
            var dataProcessingDate = new Date(year + 1, month, day);

            return dataProcessingDate < Date.now();
        }
        return false;
    }
}
