import React from "react";
import { Modal, Form, Button, Label, Icon, Message } from "semantic-ui-react";
import ControlledModal from "../../../shared/modals/ControlledModal";

export default class TransportTypeEditModal extends React.Component {
    state = this.initialState;

    get initialState() {
        return {
            modal: false,
            formData: {
                id: "",
                name: ""
            },
            errorText: "",
        };
    }

    load = () => {
        if (this.props.id)
            return callApi("TransportTypes/GetById", this.props.id).then(data => {
                this.setState({
                    formData: data,
                });
            });
    }

    save = () => {
        return callApi("TransportTypes/SaveOrCreate", this.state.formData)
            .then(data => {
                if (data.error !== "") {
                    this.setState({
                        errorText: data.error
                    });
                }
                return data;
            });
    }

    onClose = () => {
        if (this.props.onClose !== undefined)
            this.props.onClose();
        this.setState(this.initialState);
    }

    handleChange = (e, { name, value }) => {
        this.setState(prevState => {
            return { formData: { ...prevState.formData, [name]: value } };
        });
    }

    render() {
        return (
            <ControlledModal
                buttonName={this.props.children}
                buttonSize={this.props.size}
                title={this.props.title}
                onOpen={this.load}
                onClose={this.onClose}
                error={this.state.errorText}
                saveButtonName={this.props.actionButton}
                onSave={this.save}
                size="small">
                <Form>
                    <Form.Field>
                        <Form.Input label={t('Name')} name="name" value={this.state.formData.name}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                </Form>
            </ControlledModal>
        );
    }
}
