import React from "react";
import { Modal, Form, Button, Label, Icon, Message } from "semantic-ui-react";
import ControlledModal from "../../../shared/modals/ControlledModal";

export default class DocumentTypeEditModal extends React.Component {

    state = {
        modal: false,
        formData: {
            id: "",
            name: "",
            forType: "",
            isForExpiringDate: false,
        },
        errorText: "",
    };

    load = () => {
        return callApi("DocumentTypes/GetById", this.props.id).then(data => {
            this.setState({
                formData: data,
            });
        });
    }

    save = () => {
        return callApi("DocumentTypes/SaveOrCreate", this.state.formData)
            .then(data => {
                if (data.error !== "") {
                    this.setState({
                        errorText: data.error
                    });
                }
                return data;
            });
    }

    handleChange = (e, { name, value, checked }) => {
        this.setState((prevState) => {
            return { formData: { ...prevState.formData, [name]: checked !== undefined ? checked : value } };
        });
    }

    render() {
        return (
            <ControlledModal
                size="tiny"
                buttonName={this.props.children}
                buttonSize={this.props.size}
                title={this.props.title}
                onOpen={this.load}
                onClose={this.props.onClose}
                error={this.state.errorText}
                saveButtonName={this.props.actionButton}
                onSave={this.save}>
                <Form>
                    <Form.Field>
                        <Form.Input label={t('Name')} value={this.state.formData.name} name="name"
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Select label={t('For entity type')} value={this.state.formData.forType} name="forType"
                            options={[
                                { text: `${t('Visit')}`, value: `${t('Visit')}` },
                                { text: `${t('Task')}`, value: `${t('Task')}` },
                                { text: `${t('Driver')}`, value: `${t('Driver')}` },
                                { text: `${t('Transport')}`, value: `${t('Transport')}` },
                                { text: `${t('Case Security Service')}`, value: `${t('Case Security Service')}`}
                            ]}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Checkbox label={t('Has an expiring date')}
                            checked={this.state.formData.isForExpiringDate}
                            name="isForExpiringDate"
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                </Form>
            </ControlledModal>
        );
    }
}
